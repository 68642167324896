import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

import { getImageUrl } from '../../../lib/cloudinary';

const TouchIcons = ({ siteKey }) => {
  const iconSizes = [
    '57x57',
    '76x76',
    '120x120',
    '152x152',
    '180x180',
    '192x192',
  ];

  let folderName = '';
  const iconList = [
    {
      src: getImageUrl(`${folderName}apple-touch-icon-${siteKey}`, 'brandIcon'),
      sizes: '57x57',
    },
    ...iconSizes.map((i) => ({
      src: getImageUrl(
        `${folderName}apple-touch-icon-${i}-precomposed-${siteKey}`,
        'brandIcon',
      ),
      sizes: i,
    })),
    {
      src: getImageUrl(
        `${folderName}apple-touch-icon-precomposed-${siteKey}`,
        'brandIcon',
      ),
      sizes: '57x57',
    },
  ];

  return (
    <Head>
      {iconList.map((current) => (
        <link
          key={current.src}
          href={current.src}
          rel="apple-touch-icon"
          sizes={current.sizes}
        />
      ))}
    </Head>
  );
};

TouchIcons.propTypes = {
  siteKey: PropTypes.string.isRequired,
};

export default TouchIcons;
