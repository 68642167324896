/**
 * https://nextjs.org/docs/api-reference/next/head
 * title, meta or any other elements (e.g. script) need to be contained
 *  as direct children of the Head element, or wrapped into maximum one
 *  level of <React.Fragment> or arrays—otherwise the tags won't be
 *  correctly picked up on client-side navigations.
 */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Router from 'next/router';
import utils from 'lib/utils';
import copy from 'config/copy';
import GoogleAnalytics from 'components/integrations/GoogleAnalytics';
import GoogleSiteTag from 'components/integrations/GoogleSiteTag';
import GoogleTagDataLayer from 'components/integrations/GoogleTagDataLayer';
import GoogleTagManager from 'components/integrations/GoogleTagManager';
import GTagReportConversion from 'components/integrations/GoogleSiteTag/components/GTagReportConversion';
import TouchIcons from 'components/elements/TouchIcons';
import themes from 'config/css/themes';
import cloudinaryInstance, {
  baseImageConfig,
  getImageUrl,
} from 'lib/cloudinary';
import initMixpanel from 'components/integrations/Mixpanel/initMixpanel';
import useDisableTrackingProvider from 'components/providers/DisableTrackingProvider';

/** Only true in production heroku dyno */
const isProduction = process.env.ENVIRONMENT_NAME === 'production';

const preconnectDomains = [
  'https://cdn.mxpnl.com',
  'https://fonts.googleapis.com',
  'https://fonts.gstatic.com',
  'https://res.cloudinary.com',
];

const preconnectTrackingDomains = [
  'https://googleads.g.doubleclick.net',
  'https://www.googleadservices.com',
  'https://api.bounceexchange.com',
  'https://assets.pinterest.com',
  'https://platform.twitter.com',
  'https://connect.facebook.net',
];

const tagTypes = {
  recipe: [
    'course',
    'cuisine',
    'main_ingredient',
    'recipe_type',
    'dish_type',
    'holiday',
  ],
};

const determineFaviconUrl = (siteKey, key = '') => {
  let imageId = `favicon-${siteKey}`;
  if (key === 'favicon-32') {
    imageId = `${siteKey}-favicon-32x32.png`;
  } else if (key === 'favicon-16') {
    imageId = `${siteKey}-favicon-16x16.png`;
  } else if (key === 'favicon') {
    imageId = `${siteKey}-favicon.ico`;
  }

  const folderName = '';
  return getImageUrl(`${folderName}${imageId}`, 'brandIcon');
};

// used to replace single quote and fancy quote
const normyRE = /[’']+/g;

const getMetaData = (metaProp, siteName, siteKey) => {
  let { description, documentType, photo, title } = metaProp;
  const documentSiteKey = siteKey;
  const { fields, photoOverride = null, tags } = metaProp;
  if (typeof fields !== 'undefined' && typeof fields.title !== 'undefined') {
    description = fields.description;
    documentType = fields.documentType ? fields.documentType : documentType;
    photo = fields.photo;
    title = fields.title;
  }

  let tagList = [];
  let tagKeywords = '';

  const docTags = tagTypes[documentType];

  if (Array.isArray(tags) && tags.length > 0 && Array.isArray(docTags)) {
    tagList = tags
      .filter(({ fields: { categoryTitle } }) =>
        docTags.includes(categoryTitle),
      )
      .map(({ fields: { tagTitle, categoryTitle } }) => [
        categoryTitle,
        tagTitle,
      ]);

    tagKeywords = tagList.map(([, tagText]) => tagText).join(', ');
  }

  let imgUrl = photoOverride;
  if (!imgUrl && photo) {
    const cloudinaryId = photo.id || photo.public_id || photo.publicId;
    imgUrl = cloudinaryInstance.url(cloudinaryId, {
      ...baseImageConfig,
      width: 344,
      aspectRatio: '1:1',
    });
  }

  const siteTitle = siteName || copy[documentSiteKey]?.meta?.title;

  // avoid duplicate site name in meta title, while
  // accounting for single quote and the ’ fancy quote
  const normySiteTitle = siteTitle?.replace(normyRE, '');
  const normyTitle = title?.replace(normyRE, '');
  if (title && normyTitle.includes(` | ${normySiteTitle}`) === false) {
    title = `${title} | ${siteTitle}${
      documentType && documentType.toLowerCase() === 'recipe' ? ' Recipe' : ''
    }`;
  }

  return {
    description,
    documentType,
    imgUrl,
    tags: tagList,
    tagKeywords,
    title,
  };
};

const SiteHeader = ({
  canonical,
  hideFromRobots,
  includeSiteTag,
  jsonLd,
  meta: metaProp,
  queryParams,
  reducedTracking,
  siteKey: siteKeyProp,
  siteName,
  pathname,
  url: urlProp,
  isMixpanel = true,
}) => {
  const [url] = useState(urlProp);
  const [segment, setSegment] = useState(null);
  const { context: disableTracking } = useDisableTrackingProvider();

  let siteKey = siteKeyProp;
  if (url.includes('cooksillustrated')) {
    siteKey = 'cio';
  } else if (url.includes('cookscountry')) {
    siteKey = 'cco';
  } else {
    siteKey = url.includes('mystery-recipe') ? 'kids' : 'atk';
  }

  const { description, documentType, imgUrl, tags, tagKeywords, title } =
    getMetaData(metaProp, siteName, siteKey);

  // setUrl was undefined...
  const updateOgUrl = useCallback(() => {
    // setUrl(document.location.href);
  }, []);

  const orderPages = pathname?.includes('/order');
  useEffect(() => {
    if (isMixpanel && !orderPages) {
      initMixpanel();
    }
    // TODO get user segment from cookie
    utils.getUserData(() => {
      if (typeof dry !== 'undefined') {
        setSegment(dry?.segment);
      }
    });
    // gtag is not available
    setTimeout(() => {
      if (typeof gtag !== 'undefined') {
        gtag('config', 'AW-1066125178');
      }
    }, 1000);
    if (Router.router) {
      // TODO: may not be needed, updateOgUrl does nothing
      Router.router.events.on('routeChangeComplete', updateOgUrl);
    }
    return () => {
      if (Router.router) {
        // TODO: may not be needed, updateOgUrl does nothing
        Router.router.events.off('routeChangeComplete', updateOgUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const themeColor = orderPages ? '#ffffff' : themes[siteKey]?.themeColor;

  return (
    <>
      <Head>
        <meta key="meta-charset" charSet="utf-8" />
        <meta
          key="meta-viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
          name="viewport"
        />
        <meta key="meta-og-url" content={url} property="og:url" />
        {canonical && (
          <link
            key="canonical"
            href={canonical.split('?')[0].replace(/http(?!s)/g, 'https')}
            rel="canonical"
          />
        )}
        <meta
          key="meta-og-type"
          content={documentType || 'website'}
          property="og:type"
        />
        {themeColor && (
          <meta
            key="meta-theme-color"
            content={themeColor}
            name="theme-color"
          />
        )}
        {title && (
          <>
            <title key="meta-title">{title}</title>
            <meta key="meta-og-title" content={title} property="og:title" />
            <meta
              key="meta-twitter-title"
              content={title}
              name="twitter:title"
            />
          </>
        )}
        {title && (
          <>
            <title key="meta-title">{title}</title>
            <meta key="meta-og-title" content={title} property="og:title" />
            <meta
              key="meta-twitter-title"
              content={title}
              name="twitter:title"
            />
          </>
        )}
        {description && (
          <>
            <meta
              key="meta-description"
              name="description"
              content={description}
            />
            <meta
              key="meta-og-description"
              content={description}
              property="og:description"
            />
            <meta
              key="meta-twitter-description"
              content={description}
              name="twitter:description"
            />
          </>
        )}
        {Array.isArray(tags) &&
          tags.map(([tagName, tagText]) => (
            <meta
              key={`atk:${tagName}_list`}
              content={`${tagText}`}
              name={`atk:${tagName}_list`}
            />
          ))}
        {tagKeywords && (
          <meta key="atk:keywords" content={tagKeywords} name="atk:keywords" />
        )}
        <link rel="icon" type="image/png" href={determineFaviconUrl(siteKey)} />
        {imgUrl && (
          <>
            <meta content={imgUrl} key="og-image-tag" property="og:image" />
            <meta
              content={imgUrl}
              key="twitter-image-tag"
              name="twitter:image"
            />
          </>
        )}
        {segment === 'multisite' && (
          <meta
            key="apple-itunes-app"
            name="apple-itunes-app"
            content="app-id=1365223384"
          />
        )}
        {siteKey === 'atk' ? (
          <>
            <link
              key="favicon-32"
              href={determineFaviconUrl(siteKey, 'favicon-32')}
              rel="icon"
              sizes="32x32"
              type="image/png"
            />
            <link
              key="favicon-16"
              href={determineFaviconUrl(siteKey, 'favicon-16')}
              rel="icon"
              sizes="16x16"
              type="image/png"
            />
            <link
              key="favicon"
              href={determineFaviconUrl(siteKey, 'favicon')}
              rel="shortcut icon"
            />
          </>
        ) : null}
        {preconnectDomains.map((href) => (
          <link key={href} rel="preconnect" href={href} />
        ))}
        {!(disableTracking || reducedTracking) &&
          preconnectTrackingDomains.map((href) => (
            <link key={href} rel="preconnect" href={href} />
          ))}
        {!disableTracking && (
          <>
            <GoogleTagDataLayer queryParams={queryParams} key="gtm-data-tag" />
            <GoogleTagManager
              gtmId={process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}
              key="gtm-tag"
            />
            {includeSiteTag && !disableTracking
              ? [
                  <GoogleSiteTag id="AW-1066125178" key="google-site-tag" />,
                  <GTagReportConversion
                    id="AW-1066125178/nAJvCMzZkIYBEPqOr_wD"
                    key="gtag-conversion"
                  />,
                ]
              : null}
            <GoogleAnalytics />
          </>
        )}
        {jsonLd && (
          <script
            key="json-ld"
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(jsonLd),
            }}
          />
        )}
        {(hideFromRobots || !isProduction) && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Head>
      <TouchIcons siteKey={siteKey} />
    </>
  );
};

SiteHeader.propTypes = {
  canonical: PropTypes.string,
  includeSiteTag: PropTypes.bool,
  jsonLd: PropTypes.object,
  meta: PropTypes.object.isRequired,
  queryParams: PropTypes.object,
  reducedTracking: PropTypes.bool.isRequired,
  siteKey: PropTypes.string.isRequired,
  siteName: PropTypes.string,
  pathname: PropTypes.string,
  /** Fully qualified url for the current page */
  url: PropTypes.string.isRequired,
  hideFromRobots: PropTypes.bool,
  isMixpanel: PropTypes.bool,
};

export default SiteHeader;
