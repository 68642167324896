'use client';

import React from 'react';
import { SvgProvider } from '@americastestkitchen/ui/svg';
import { SvgProvider as SvgProviderMise } from '@americastestkitchen/mise/components/Icons/SvgProvider/SvgProvider';
import '@americastestkitchen/ui/styles.css';
import '@americastestkitchen/mise/styles.css';
import 'mise/src/styles/styles.scss';
import ErrorPage from 'components/containers/ErrorPage';
import UIHeaderV2 from './UIHeaderV2';
import UIFooterV2 from './UIFooterV2';

export const ErrorScreen = ({ errorCode }: { errorCode: number }) => {
  return (
    <>
      <SvgProvider />
      <SvgProviderMise />
      <UIHeaderV2 />
      <ErrorPage not404={errorCode !== 404} />
      <div id="atk-global-footer">
        <UIFooterV2 />
      </div>
    </>
  );
};
